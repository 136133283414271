import { getCart } from "@api/Cart";
import { Order } from "@csapi/provisioning/api";
import { createContext, useContext, useEffect, useState } from "react";
import { AuthContext } from "./AuthProvider";
import { generateEmptyOrder } from "@utils/OrderUtils";

interface CartContextType {
    cart: Order,
    setCart: (newCart: Order)=>void
    resetCart: ()=>unknown
    loadCart: ()=>Promise<Order>
}

const CartContext = createContext<CartContextType>(null!)

function CartProvider({ children }: { children: React.ReactNode }) {
    const emptyCart = generateEmptyOrder()
    const [cart, _setCart] = useState<Order>(emptyCart)
    const auth = useContext(AuthContext)
    const [loadIntervalId, setLoadIntervalId] = useState<NodeJS.Timer>()

    useEffect(()=>{
        if(auth.userData?.id) {
            loadCart()
        }
    }, [auth.userData?.id])

    useEffect(()=>{
        if(cart.additional_info?.payplug_payment_approval_token) {
            if(loadIntervalId===undefined) {
                setLoadIntervalId(setInterval(() => {
                    loadCart()
                }, 30 * 1000))
            }
        } else{
            clearInterval(loadIntervalId)
            setLoadIntervalId(undefined)
        }
    }, [cart])

    useEffect(() => {
        const handleUpdateCartEvent = async (event:any) => {
            const {key, newValue} = event
            if(key==="cart_update_sync") {
                const response = await getCart()
                _setCart(response ?? emptyCart)
            }
        };

        window.addEventListener("storage", handleUpdateCartEvent);
        return () => window.removeEventListener("storage", handleUpdateCartEvent);
    }, [])

    const loadCart = async()=>{
        if(!auth.userData?.id) return emptyCart
        const response = await getCart()
        setCart(response ?? emptyCart)
        return response ?? emptyCart
    }

    const resetCart = ()=>{
        return setCart(emptyCart)
    }

    const setCart = (newCart: Order)=>{
        _setCart(newCart)
        localStorage.setItem("cart_update_sync", (Math.random() + 1).toString(36).substring(2))
        return
    }


    const value = { cart, setCart, resetCart, loadCart }
    return <CartContext.Provider value={value}>{children}</CartContext.Provider>
}

export { CartProvider, CartContext } 