import { Order } from "@csapi/shop"
import { getItemsByIdList } from "@api/Items";
import { Item } from "@csapi/provisioning";
import { NotFoundItemInArray } from "../../../src/interfaces/Items";
import { OrderItemWithInfo } from "@interfaces/Orders";
import Localization from "@localization/Index";
import { checkForm } from "./FormUtils";
import { OrderShippingMethod } from "../../../src/interfaces/Orders";

export enum OrderStatus {
    PENDING= "PENDING", 
    CONFIRMED= "CONFIRMED", 
    CANCELED= "CANCELED", 
    REFURBISHED= "REFURBISHED", 
    SHIPPED= "SHIPPED", 
    PARTIALLY_SHIPPED= "PARTIALLY_SHIPPED", 
    OTHER= "OTHER"
}

export const orderStatuses = [
    { status: OrderStatus.PENDING, icon: "bi-pencil-square", color: "warning", label: Localization.PAGES.ORDERS.STATUS.PENDING},
    { status: OrderStatus.CONFIRMED, icon: "bi-check-square", color: "success", label: Localization.PAGES.ORDERS.STATUS.CONFIRMED},
    { status: OrderStatus.CANCELED, icon: "bi-dash-square", color: "danger", label: Localization.PAGES.ORDERS.STATUS.CANCELED},
    { status: OrderStatus.REFURBISHED, icon: "bi-arrow-repeat", color: "muted", label: Localization.PAGES.ORDERS.STATUS.REFURBISHED},
    { status: OrderStatus.SHIPPED, icon: "bi-truck", color: "primary", label: Localization.PAGES.ORDERS.STATUS.SHIPPED},
    { status: OrderStatus.PARTIALLY_SHIPPED, icon: "bi-truck-flatbed", color: "info", label: Localization.PAGES.ORDERS.STATUS.PARTIALLY_SHIPPED},
    { status: OrderStatus.OTHER, icon: "bi-question-circle", color: "dark", label: Localization.PAGES.ORDERS.STATUS.OTHER},
]

/**
 * Get all the order status rendering elements
 * @param status the order status
 */
export const getOrderStatus = (status?:OrderStatus)=>{
    return orderStatuses.find(orderStatus=>orderStatus.status===status) ?? orderStatuses.find(orderStatus=>orderStatus.status===OrderStatus.OTHER)!
}

export const getOrderItemsWithInfo = async (order:Order, lang:string)=>{
    try {
        const orderItemsIds = order.items?.map(item=>item.item_id?.$oid) as string[]
        const items = await getItemsByIdList(orderItemsIds)
        const orderItems:OrderItemWithInfo[] = items.map(item =>{
            const notFoundItem = item as NotFoundItemInArray
            if(notFoundItem.index!== undefined) {
                // manage return value of not found element
                const orderItemNotFoundOriginal = order.items?.[notFoundItem.index]
                const { item_id, price_adjustments, total_price_tax_incl, price, qty, unit_price_tax_incl  } = orderItemNotFoundOriginal!

                return {
                    id: item_id?.$oid ?? "",
                    sku: "",
                    img: "",
                    skuParent: "N.D.",
                    description: "N.D.",
                    brand: "N.D.",
                    variation: price_adjustments?.[0]?.reason_id,
                    variationType: price_adjustments?.[0]?.reason_title,
                    qty,
                    cost: {amount: price, currency: total_price_tax_incl?.currency ?? "EUR"},
                    unit_price: {amount: unit_price_tax_incl?.amount, currency: unit_price_tax_incl?.currency ?? "EUR"},
                }
            }

            const foundItem = item as Item

            const foundOrderItemOriginal = order.items?.find(i=>i.item_id?.$oid===foundItem.item_id?.$oid)
            const { price_adjustments, total_price_tax_incl, price, qty, unit_price_tax_incl  } = foundOrderItemOriginal!

            const title = foundItem?.locs?.singles?.title?.[lang] ?? (foundItem?.locs?.singles?.title?.["it"] ?? foundItem?.props?.['title'])

            return {
                id: foundItem.item_id?.$oid ?? "",
                sku: foundItem.sku,
                img: foundItem.imgs?.[0].url ?? "",
                skuParent: (foundItem.props?.parent_sku ?? "N.D.") as string,
                description: (title ?? "N.D.") as string,
                brand: (foundItem.props?.brand ?? "N.D.") as string,
                variation: price_adjustments?.[0]?.reason_id,
                variationType: price_adjustments?.[0]?.reason_title,
                qty,
                cost: {amount: price, currency: total_price_tax_incl?.currency ?? "EUR"},
                unit_price: {amount: unit_price_tax_incl?.amount, currency: unit_price_tax_incl?.currency ?? "EUR"},
                cat_ids: foundItem.cats,
                barcode: foundItem.barcodes?.[0].code ?? (foundItem.props?.barcode as unknown as string)
            }
        })

        return orderItems
    } catch(err) {
        console.log(err)
        throw err
    }
}

// add the head fields needed for the BeCommerce
export const generateEmptyOrder = ()=>{
    const order: Order = {
        order_status: "PENDING",
        tot_qty: 0,
        tot_amount: {
            amount: 0,
            currency: "EUR"
        },
        items: []
    }

    return order
}

export enum PaymentMethodEnum {
    BANK_TRANSFER = "000000000000000000000001",
    PAYPAL = "000000000000000000000002",
    CREDIT_CARD = "000000000000000000000003"
}

export enum ShippingMethodsEnum {
    Customer = "000000000000000000000001",
    DhlExpress = "000000000000000000000002"
}

export const getBillingInfoCheckoutErrors = (order: Order)=>{
    const { billing_info={} } = order
    const { address={}} = billing_info
    const { first_name, last_name, company_name, state_code, street, city, province, province_code, zip, contact1, contact2 } = address 

    const checkAliasFunction = ()=>{
        if(company_name || (first_name && last_name)) {
            return undefined
        }

        return Localization.COMPONENTS.ADDRESS_SELECTOR.ERRORE_NOMINATIVO
    }

    const response = checkForm([
        {id: Localization.COMPONENTS.ADDRESS_SELECTOR.NOME, value: first_name, customCheckFunction: checkAliasFunction},
        {id: Localization.COMPONENTS.ADDRESS_SELECTOR.COGNOME, value: last_name, customCheckFunction: checkAliasFunction},
        {id: Localization.COMPONENTS.ADDRESS_SELECTOR.RAGIONE_SOCIALE, value: company_name, customCheckFunction: checkAliasFunction},
        {id: Localization.COMPONENTS.ADDRESS_SELECTOR.INDIRIZZO, value: street, required:true},
        {id: Localization.COMPONENTS.ADDRESS_SELECTOR.CITTA, value: city, required:true},
        {id: Localization.COMPONENTS.ADDRESS_SELECTOR.PROVINCIA, value: province, required:true},
        {id: Localization.COMPONENTS.ADDRESS_SELECTOR.SIGLA_PROVINCIA, value: province_code, required:true},
        {id: Localization.COMPONENTS.ADDRESS_SELECTOR.NAZIONE, value: state_code, required:true},
        {id: Localization.COMPONENTS.ADDRESS_SELECTOR.CODICE_POSTALE, value: zip, required:true},
        {id: Localization.COMPONENTS.ADDRESS_SELECTOR.EMAIL, value: contact1, regex: "^[\\w.-]+@[\\w.-]+\\.[A-Za-z]{2,}$"},
        {id: Localization.COMPONENTS.ADDRESS_SELECTOR.TELEFONO, value: contact2, boundaries: {min: 0, max: 16}, regex: {expression: "^\\+[1-9]\\d{1,14}$", message: Localization.COMPONENTS.ADDRESS_SELECTOR.ERRORE_TELEFONO}},
    ])

    return response

}

export const getShippingInfoCheckoutErrors = (order: Order)=>{
    const { shipping_info={} } = order
    const { address={}} = shipping_info
    const { first_name, last_name, company_name, state_code, street, city, province, province_code, zip, contact1, contact2 } = address 

    const checkAliasFunction = ()=>{
        if(company_name || (first_name && last_name)) {
            return undefined
        }

        return Localization.COMPONENTS.ADDRESS_SELECTOR.ERRORE_NOMINATIVO
    }

    const response = checkForm([
        {id: Localization.COMPONENTS.ADDRESS_SELECTOR.NOME, value: first_name, customCheckFunction: checkAliasFunction},
        {id: Localization.COMPONENTS.ADDRESS_SELECTOR.COGNOME, value: last_name, customCheckFunction: checkAliasFunction},
        {id: Localization.COMPONENTS.ADDRESS_SELECTOR.RAGIONE_SOCIALE, value: company_name, customCheckFunction: checkAliasFunction},
        {id: Localization.COMPONENTS.ADDRESS_SELECTOR.INDIRIZZO, value: street, required:true},
        {id: Localization.COMPONENTS.ADDRESS_SELECTOR.CITTA, value: city, required:true},
        {id: Localization.COMPONENTS.ADDRESS_SELECTOR.PROVINCIA, value: province, required:true},
        {id: Localization.COMPONENTS.ADDRESS_SELECTOR.SIGLA_PROVINCIA, value: province_code, required:true},
        {id: Localization.COMPONENTS.ADDRESS_SELECTOR.NAZIONE, value: state_code, required:true},
        {id: Localization.COMPONENTS.ADDRESS_SELECTOR.CODICE_POSTALE, value: zip, required:true},
        {id: Localization.COMPONENTS.ADDRESS_SELECTOR.EMAIL, required: true, value: contact1, regex: "^[\\w.-]+@[\\w.-]+\\.[A-Za-z]{2,}$"},
        {id: Localization.COMPONENTS.ADDRESS_SELECTOR.TELEFONO, required: true, value: contact2, boundaries: {min: 0, max: 16}, regex: {expression: "^\\+[1-9]\\d{1,14}$", message: Localization.COMPONENTS.ADDRESS_SELECTOR.ERRORE_TELEFONO}},
    ])

    return response

}

export const getShippingMethodsCheckoutError = (order: Order)=>{
    const method = order.additional_info?.shipping_method as unknown as OrderShippingMethod
    if(!method) return Localization.COMPONENTS.SHIPPING_METHOD.ERRORE_METODO_MANCANTE
    if(!["DHL_EXPRESS", "CUSTOMER"].includes(method)) return Localization.COMPONENTS.SHIPPING_METHOD.ERRORE_METODO_NON_GESTITO(method)
    return undefined
}

export const getPaymentMethodsCheckoutError = (order: Order)=>{
    const method = order.billing_info?.payment_method
    if(!method) return Localization.COMPONENTS.PAYMENT_METHOD.ERRORE_METODO_MANCANTE
    if(!["BANK_TRANSFER", "CREDIT_CARD_GENERIC", "PAYPAL"].includes(method)) return Localization.COMPONENTS.PAYMENT_METHOD.ERRORE_METODO_NON_GESTITO(method)
    return undefined
}
