import { AxiosError } from "axios"

const checkResponseStatus = (error:AxiosError) =>{
    if (error.name == 'CanceledError') {
        return false
    }
    console.error(error?.response?.status)
    if(error?.response?.status===401) {
        window.location.href = "/login"
        return true
    }
    if(error?.response?.status===403) {
        window.location.href = "/accessdenied"
        return true
    }
    if(error?.response?.status===503) {
        window.location.href = "/maintenance"
        return true
    }
    return false
}

export { checkResponseStatus }